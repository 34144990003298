@charset "utf-8";
//森で食べる
.class-vesso{
	.gd_main{
		background-image: url(/assets/img/class-vesso/cv_back01.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		height:100vh;
		position: relative;
		z-index:0;
		
		@include space15;
		.gd_main_inner{
			max-width: 293px;
			margin: 0 auto;
			height: 48vh;
			position: relative;
			.main_h1_text_out{
				h1{
					max-width: 293px;
					padding-bottom: 22px;
					margin-bottom: 29px;
					position: relative;
					@media #{$size_600}{
						max-width: 200px;
					}
					img{
						width: 100%;
					}
					&:before{
						position: absolute;
						content:"";
						display: block;
						width: 116px;
						height: 1px;
						background-color: #fff;
						bottom: 0;
						left: 0;
						right: 0;
						margin:auto;
						transform:scaleX(0);
					}

				}
				p{
					max-width: 141px;
					margin: 0 auto;
					display: inline-block;
					img{
						width: 100%;
					}

				}
			}
			.scroll{
				position: absolute;
				left: 0;
				right: 0;
				margin:auto;
				bottom: 0;
				text-align: center;
				.scroll_txt{
					width: 67px;
					margin: 0 auto 1px auto;
					img{
						width: 100%;
					}
				}
				.scroll_arrow{
					width: 19px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}

			}
		}
	}

	.gd_box01{
		padding-top: 110px;
		padding-bottom: 109px;
		box-sizing: border-box;
		position: relative;
		z-index:0;
		@media #{$size_800}{
			padding-top: 80px;
			padding-bottom: 80px;
		}
		&:before{
			width:31.7708333333%;
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #81B975;
			z-index: -1;
			height: 100%;
			@media #{$size_1000}{
				display: none;
			}

		}
		.lr50{
			max-width: 1400px;
			margin: 0 auto;
			box-sizing: border-box;
			@media #{$size_1400}{
				padding-left: 40px;
				padding-right: 40px;
			}
			@media #{$size_1000}{
				padding-left: 0;
				padding-right: 0;
			}
			.lr50_inner{
				display: block;
				@media #{$size_1000}{
				@include space15;
				&:first-child{
						margin-bottom: 30px;
					}
				}
				.detail_slider{
					max-width: 700px;
					margin: 0 auto;
					.slick-dots{
						right: 0;
						width: auto;
						display: inline-block !important;
						bottom:-30px;
						li{
							width: 14px;
							height: 14px;
							margin: 0px 0 0 14px;
							button{
								width: 100%;
								height: 100%;
								padding: 0;
								background-color: #d6d6d6;
								border-radius: 7px;
								&:before{
									display: none;
								}
							}
						}
						.slick-active{
							button{
								background-color: #81B975;
							}
						}

					}
				}
				
				.gd_txt01{
					margin-left: 90px;
					max-width: 450px;
					@media #{$size_1400}{
						margin-left: 30px;
					}
					@media #{$size_1000}{
						margin: 25px 0 0 0;
					}
					@media #{$size_800}{
						max-width: 100%;
					}
					.detail_h201{
						margin-bottom: 38px;
						@media #{$size_800}{
							margin-bottom: 20px;
						}
					}
					
				}
				img{
					width: 100%;
				}
				p{
					margin: 0 auto;
				}
				&:nth-child(2){
					max-width: 700px;
					margin: 0 auto;
				}
			}
			.gd_txt01--out{
				display: flex;
				align-items:center;
			}
		}
	}

	.gd_box02{
		background-image: url(/assets/img/class-vesso/cv_back02.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position:center;
		padding-top: 88px;
		padding-bottom: 79px;
		@include space15;
		.gd_box02_inner{
			.detail_h2_title02{
				h2{
					color:#fff;
				}
				p{
					color:#fff;
				}
				margin-bottom: 45px;
			}
		}
		.detail_p01{
			color:#fff;
			text-align: center;
			@media #{$size_600}{
				br{
					display: none;
				}
			}
		}
	}

	.gd_box03{
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		width:100%;
		@media #{$size_800}{
			flex-direction:column;
		}
		.gd_box03_inner01{
			width: 50%;
			overflow: hidden;
			@media #{$size_800}{
				width: 100%;
			}

				&:first-child{
					.gd_box03_inner{
					background-image: url(/assets/img/class-vesso/cv_back03--01.png);
					}
				}
				&:nth-child(2){
					.gd_box03_inner{
					background-image: url(/assets/img/class-vesso/cv_back03--02.png);
					}
				}
				&:nth-child(3){
					.gd_box03_inner{
					background-image: url(/assets/img/class-vesso/cv_back03--03.png);
					}
				}
				&:nth-child(4){
					.gd_box03_inner{
					background-image: url(/assets/img/class-vesso/cv_back03--04.png);
					}
				}
				
			.gd_box03_inner{
				
				background-size: cover;
				background-repeat: no-repeat;
				background-position:center;
				position: relative;
				z-index: 0;
				height: 400px;
				display: block;
				transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
				&:hover{
					transform: scale(1.07,1.07);
					transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
				}
				
				&:before{
					position: absolute;
					width: 100%;
					height: 100%;
					display: block;
					background-color: #000;
					opacity: 0.3;
					z-index: 1;
					content:"";
					display: block;
					transition: 1.3s cubic-bezier(.165,.84,.44,1);
				}
				&:hover{
					&:before{
						opacity: 0;
						transition: 1.3s cubic-bezier(.165,.84,.44,1);
					}
				}
				a{
					display: block;
					width: 100%;
					height: 100%;
					color:#fff;
					position: absolute;
					z-index: 2;
					text-align: center;
					display: flex;
					align-items:center;
					transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
					.detail_h3_title01--02{
						display: inline-block;
						margin: 0 auto;
						text-align: center;
						@include space15;
						p{
							&:first-child{

							}
							&:nth-child(3){
								
							}
						}
					}
					h3{
						letter-spacing: 0.1em;
					}

				}
			}
		}
	}
	.fa_box06{
		text-align: center;
		.detail_h2_title02{
			margin-bottom: 60px;
		}
		.fa_box06_inner{
			max-width: 1000px;
			margin: 0 auto;
			overflow: hidden;
			.fa_box06_left{
				float: left;
				width: 50%;
				@include space15;
				padding-left: 30px;
				padding-right: 30px;
				@media #{$size_1000}{
					float: none;
					width: 100%;
					margin: 0 auto;
					max-width: 500px;
					margin-bottom: 60px;
				}
			}
			.fa_box06_right{
				float:right;
				width: 50%;
				@include space15;
				padding-left: 30px;
				padding-right: 30px;
				
				height: 696px;
				@media #{$size_1000}{
					float: none;
					width: 100%;
					margin: 0 auto;
					max-width: 500px;
					margin-bottom: 30px;
				}
				.fb-page{
					width: 100%;
				}
				#twitter-widget-0{
					border:solid 1px #eee !important;
					box-sizing: border-box;
				}
				.cv_snap_out{
						@media #{$size_600}{
							.cv_snap_out_inner{
								display: none;
							}
						}
						.cv_snap_out_inner{
							position: relative;
							a{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								display: block;
							}
						}
						
					
				}
				.insta_logo{
					display: none;
					@media #{$size_600}{
					display: block;
					}
				}
			}
		}

	}
	.gd_box07{
		background-color: #81B975;
		padding-top: 93px;
		padding-bottom: 108px;
		.detail_h2_title02{
			margin-bottom: 81px;
			h2{
				color: #fff;
			}
			p{
				color: #fff;
			}
		}
		
		.gd_tel{
			text-align: center;
			@include hiragino;
			@include space15;

			p{
				&:first-child{
					color: #fff;
					@include fontsize(18);
					line-height: 1.2;
					margin-bottom: 29px;
				}
				&:nth-child(4){
					color: #0C0C0C;
					@include fontsize(14);
					@include hiragino;
					line-height: 1.2;
					font-weight: bold;
					span{
						background-color: #0C0C0C;
						color:#fff;
						@include fontsize(12);
						display: inline-block;
						padding:2px 4px 2px 4px;
						margin-left: 4px;
						margin-right: 4px;
					}
				}
			}
			figure{
				max-width: 297px;
				margin: 0 auto 15px auto;
				line-height: 1.2;
				img{
					width: 100%;
				}
			}
			.syukuhaku_tel{
				@include fontsize(20);
				@include font04;
				color:#0C0C0C;
				margin-bottom: 15px;
			}
		}
	}

	.gd_box08{
		padding-top: 80px;
		padding-bottom: 85px;
		@media #{$size_700}{
			@include space15;
		}
		a{
			display: block;
			border:1px solid #0c0c0c;
			box-sizing: border-box;
			margin: 0 auto;
			max-width: 660px;
			padding-top: 25px;
			padding-bottom: 28px;
			overflow: hidden;
			display: flex;
			flex-direction:row;
			@media #{$size_700}{
				flex-direction:column;
				@include space15;
			}
			@include transition01;
			&:hover{
				@include transition01;
				background-color: #d2e6cd;
				border:solid 1px #d2e6cd;
				.gd08_left{
				border-right: solid 1px #fff;
					@media #{$size_700}{
						border-right:none;
					}
				}
			}
			.gd08_left{
				width: 403px;
				border-right: solid 1px #d6d6d6;
				display: flex;
				align-items:center;
				@media #{$size_700}{
					width: 100%;
					border-right: none;
				}
				figure{
					max-width: 195px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}
			}
			.gd08_right{
				display: flex;
				align-items:center;
				text-align: center;
				width: 254px;
				height: 95px;
				@media #{$size_700}{
					width: 100%;
					height: auto;
					padding-top: 15px;
				}
				p{
					color:#0c0c0c;
					text-align: center;
					margin: 0 auto;
				}
			}

		}
	}
}