@charset "utf-8";
.footer_new{
	#pagetop{
		bottom:90px;
		a {
		    width: 58px;
		    height: 58px;
		    img{
		    	width: 58px;
		    	height: 58px;
		    }
		}

	}
	&:after{
		display: none;
	}
}

.nt_footer_out{
	position: relative;
	background-color: #fff;
}