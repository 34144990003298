@charset "utf-8";
//森と遊ぶ
.foret-aventure{
	.gd_main{
		background-image: url(/assets/img/foret-aventure/fa_main_back01.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		height:100vh;
		position: relative;
		z-index:0;
		@include space15;
		.gd_main_inner{
			max-width: 558px;
			margin: 0 auto;
			height: 40vh;
			position: relative;
			.main_h1_text_out{
				h1{
					max-width: 558px;
					padding-bottom: 30px;
					margin-bottom: 29px;
					position: relative;
					img{
						width: 100%;
					}
					&:before{
						position: absolute;
						content:"";
						display: block;
						width: 115px;
						height: 1px;
						background-color: #fff;
						bottom: 0;
						left: 0;
						right: 0;
						margin:auto;
						transform:scaleX(0);
					}

				}
				p{
					max-width: 115px;
					margin: 0 auto;
					display: inline-block;
					img{
						width: 100%;
					}

				}
			}
			.scroll{
				position: absolute;
				left: 0;
				right: 0;
				margin:auto;
				bottom: 0;
				text-align: center;
				.scroll_txt{
					width: 67px;
					margin: 0 auto 1px auto;
					img{
						width: 100%;
					}
				}
				.scroll_arrow{
					width: 19px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}

			}
		}
	}

	.gd_box01{
		padding-top: 122px;
		padding-bottom: 109px;
		box-sizing: border-box;
		position: relative;
		z-index:0;
		@media #{$size_800}{
			padding-top: 80px;
			padding-bottom: 80px;
		}
		&:before{
			width:31.7708333333%;
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #F8B95D;
			z-index: -1;
			height: 100%;
			@media #{$size_1000}{
				display: none;
			}

		}
		.lr50{
			max-width: 1400px;
			margin: 0 auto;
			box-sizing: border-box;
			@media #{$size_1400}{
				padding-left: 40px;
				padding-right: 40px;
			}
			@media #{$size_1000}{
				padding-left: 0;
				padding-right: 0;
			}
			.lr50_inner{
				display: block;
				@media #{$size_1000}{
					@include space15;
					&:first-child{
						margin-bottom: 30px;
					}
				}
				.detail_slider{
					max-width: 700px;
					margin: 0 auto;
					.slick-dots{
						right: 0;
						width: auto;
						display: inline-block !important;
						bottom:-30px;
						li{
							width: 14px;
							height: 14px;
							margin: 0px 0 0 14px;
							button{
								width: 100%;
								height: 100%;
								padding: 0;
								background-color: #d6d6d6;
								border-radius: 7px;
								&:before{
									display: none;
								}
							}
						}
						.slick-active{
							button{
								background-color:#F8B95D;
							}
						}

					}
				}

				.gd_txt01{
					margin-left: 90px;
					max-width: 450px;
					@media #{$size_1400}{
						margin-left: 30px;
					}
					@media #{$size_1000}{
						margin: 25px 0 0 0;
					}
					@media #{$size_800}{
						max-width: 100%;
					}
					.detail_h201{
						margin-bottom: 38px;
						@media #{$size_800}{
							margin-bottom: 20px;
						}
					}

				}
				img{
					width: 100%;
				}
				p{
					margin: 0 auto;
				}
				&:nth-child(2){
					max-width: 700px;
					margin: 0 auto;
				}
				.e_time_out{
					width: 100%;
					overflow: hidden;
					margin-top: 20px;
					p{
						display: inline-block;
						float: left;
						&:first-child{
							@include fontsize(14);
							color:#fff;
							background-color: #F8B95D;
							padding: 0px 4px;
							margin-right: 13px;

						}
						&:nth-child(2){
							@include fontsize(17);
							font-weight: bold;
							vertical-align: bottom;

						}
						&:nth-child(3){
							padding-top: 4px;
							@include fontsize(13);
							font-weight: bold;
							vertical-align: bottom;
						}
					}
				}
			}
			.gd_txt01--out{
				display: flex;
				align-items:center;
			}
		}
	}

	.gd_box02{
		background-image: url(/assets/img/foret-aventure/fa_back02.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position:center;
		padding-top: 75px;
		padding-bottom: 70px;
		overflow: hidden;
		@include space15;
		.gd_box02_inner{
			overflow: hidden;
			.detail_h2_title02{
				h2{
					color:#fff;
				}
				p{
					color:#fff;
				}
			}
		}
		.detail_p01{
			color:#fff;
			text-align: center;
			@media #{$size_600}{
				br{
					display: none;
				}
			}
		}
	}



	.fa_box01{
		display: flex;
		flex-direction: row;
		@media #{$size_1000}{
			flex-direction: column;
		}
		.fa01_left{
			width: 50%;
			background-image: url(/assets/img/foret-aventure/fa_back03.png);
			background-size: cover;
			background-repeat: no-repeat;
			background-position:center left;
			@media #{$size_1000}{
				width: 100%;
				height: 400px;
			}

		}
		.fa01_right{
			width: 50%;
			display: flex;
			align-items:center;
			padding-top: 108px;
			padding-bottom: 116px;
			text-align: center;
			@include space15;
			@media #{$size_1000}{
				width: 100%;
			}
			.fa_right01_inner{
				margin: 0 auto;
				text-align: left;
				.detail_h3_title02{
					margin-bottom: 15px;
					h3{
						letter-spacing: 0.2em;
					}
					p{
						letter-spacing: 0.2em;
					}
				}
				.fa_txt_box01_out{
					overflow: hidden;
					margin-top: 16px;
					.fa01_txt_box01{
						float: left;
						dl{
							dt{
								color:#fff;
								background-color: #f8b95d;
								display: inline-block;
								line-height: 1.2;
								float: left;
								@include fontsize(14);
								padding:5px 6px 4px 6px;
								margin-right: 6px;
								margin-top: 9px;
							}
							dd{
								display: inline-block;
								line-height: 1.2;
								float: left;
								display: flex;
								align-items:center;
								span{
									&:first-child{
										@include fontsize(26);
										@include lato;
										font-weight: bold;
									}
									&:nth-child(2){
										@include fontsize(35);
										@include lato;
										font-weight: bold;

									}
									&:nth-child(3){
										@include fontsize(13);
									}
								}
							}
						}
					}

				}
				.fa01_txt_box02{
					margin-top: 24px;
					dl{
						display:flex;
						flex-direction: row;
						dt{
							border:solid 1px #990000;
							color:#990000;
							box-sizing: border-box;
							width: 86px;
							height: 50px;
							@include fontsize(13);
							display: flex;
							align-items:center;
							text-align: center;
							span{
								display: inline-block;
								margin: 0 auto;
							}
						}
						dd{
							display: flex;
							align-items:center;
							margin-left: 17px;
							span{
								b{
									font-weight: bold;
								}
							}

						}
					}
				}
			}
		}
	}

	.fa_box01--02{
		flex-direction: row-reverse;
		@media #{$size_1000}{
			flex-direction: column;
		}
		.fa01_left{
			background-image: url(/assets/img/foret-aventure/fa_back04.png);
			background-position:center;
		}
	}

	.fa_box01--03{
		@media #{$size_1000}{
			flex-direction: column;
		}
		.fa01_left{
			background-image: url(/assets/img/foret-aventure/fa_back06.png);
			background-position:center;
		}
	}


	.fa_box02{
		background-image: url(/assets/img/foret-aventure/fa_back05.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position:center;
		padding-top: 70px;
		padding-bottom: 70px;
		.fa_btn01{
			text-align: center;
			a{
				display: block;
				margin: 0 auto;
				border: solid 1px #fff;
				@include fontsize(18);
				color:#fff;
				width: 280px;
				height: 60px;
				line-height: 1.2;
				border-radius: 30px;
				position: relative;
				@include transition01;
				&:before{
					background-image: url(/assets/img/glamorous-dining/gd_contact_arrow01.png);
				    background-size: 16px 21px;
				    width: 16px;
				    height: 21px;
				    content: "";
				    display: block;
				    position: absolute;
				    top: 19px;
				    right: 23px;
				}
				span{
					margin-top: 20px;
					display: block;
				}
				&:hover{
					background-color: #0c0c0c;
					@include transition01;
					border: solid 1px #0c0c0c;
				}

			}
		}
	}

	.fa_box03{
		background-color: #fafafa;
		padding-top: 89px;
		padding-bottom: 100px;
		text-align: center;
		.fa_box03_inner{
			max-width: 1147px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			flex-wrap:wrap;
			justify-content:space-between;
			padding-top: 100px;
			padding-bottom: 70px;
			@media #{$size_1200}{
				max-width: 700px;
			}
			.fa_box03_link{
				@include space15;
				width: 248px;
				@media #{$size_1200}{
						width: 50%;
						margin-bottom:60px;
				}
				@media #{$size_600}{
					width: 100%;
				}
				a{

					display: block;
					color: #0c0c0c;
					position: relative;

					.fa_icon01{
						width: 121px;
					}
					.fa_icon02{
						width: 114px;
					}
					.fa_icon03{
						width: 129px;
					}
					.fa_icon04{
						width: 108px;
					}
					p{
						margin: 0 auto;
						&:first-child{
							display: flex;
							align-items:center;
							height: 90px;
							margin-bottom: 36px;
							img{
								width: 100%;
							}
						}
						&:nth-child(2){
							@include fontsize(28);
							@include lato;
							letter-spacing: 0.2em;
							font-weight: bold;
							line-height: 1.2;
							margin-bottom: 14px;
						}
						&:nth-child(3){
							@include fontsize(15);
							@include meirio;
							font-weight: bold;
							line-height: 1.2;
							margin-bottom: 38px;
						}
						&:nth-child(4){
							width: 20px;
							position: absolute;
							bottom: -58px;
							left: 0;
							right: 0;
							margin:auto;
							@include transition01;
							img{
								width: 100%;
							}
						}

					}
					&:hover{
						p{
							&:nth-child(4){
								bottom: -68px;
								@include transition01;
							}
						}
					}


				}
			}

		}
		.fa_btn01--02{
			text-align: center;
			a{
				display: block;
				margin: 0 auto;
				@include fontsize(18);
				color:#fff;
				width: 320px;
				height: 60px;
				line-height: 1.2;
				border-radius: 30px;
				position: relative;
				@include transition01;
				background-color: #0d0d0d;
				&:before{
					background-image: url(/assets/img/glamorous-dining/gd_contact_arrow01.png);
				    background-size: 16px 21px;
				    width: 16px;
				    height: 21px;
				    content: "";
				    display: block;
				    position: absolute;
				    top: 19px;
				    right: 23px;
				}
				span{
					padding-top: 20px;
					display: block;
				}
				&:hover{
					background-color: #f8b95d;
					@include transition01;
				}

			}
		}
	}

	.gd_box06{
		padding-top: 90px;
		padding-bottom: 90px;
	}
	.fa_box06{
		text-align: center;
		.detail_h2_title02{
			margin-bottom: 60px;
		}
		.fa_box06_inner{
			max-width: 1000px;
			margin: 0 auto;
			overflow: hidden;
			.fa_box06_left{
				float: left;
				width: 50%;
				@include space15;
				padding-left: 30px;
				padding-right: 30px;
				@media #{$size_1000}{
					float: none;
					width: 100%;
					margin: 0 auto;
					max-width: 500px;
					margin-bottom: 60px;
				}
			}
			.fa_box06_right{
				float:right;
				width: 50%;
				@include space15;
				padding-left: 30px;
				padding-right: 30px;
				height: 696px;
				overflow-y:scroll;
				@media #{$size_1000}{
					float: none;
					width: 100%;
					margin: 0 auto;
					max-width: 500px;
					margin-bottom: 30px;
				}
				.fb-page{
					width: 100%;
				}
				#twitter-widget-0{
					border:solid 1px #eee !important;
				}
			}
		}

	}

	.gd_box07{
		background-color: #f8b95d;
		padding-top: 93px;
		padding-bottom: 108px;
		.detail_h2_title02{
			margin-bottom: 81px;
			h2{
				color: #fff;
			}
			p{
				color: #fff;
			}
		}

		.gd_tel{
			text-align: center;
			@include hiragino;
			@include space15;

			p{
				&:first-child{
					color: #fff;
					@include fontsize(18);
					line-height: 1.2;
					margin-bottom: 29px;
				}
				&:nth-child(3){
					color: #0C0C0C;
					@include fontsize(14);
					@include hiragino;
					line-height: 1.2;
					font-weight: bold;
					span{
						background-color: #0C0C0C;
						color:#fff;
						@include fontsize(12);
						display: inline-block;
						padding:2px 4px 2px 4px;
						margin-left: 4px;
						margin-right: 4px;
					}
				}
			}
			figure{
				max-width: 318px;
				margin: 0 auto 23px auto;
				line-height: 1.2;
				img{
					width: 100%;
				}
			}
		}
	}

	.gd_box08{
		padding-top: 80px;
		padding-bottom: 85px;
		@media #{$size_700}{
			@include space15;
		}
		a{
			display: block;
			border:1px solid #0c0c0c;
			box-sizing: border-box;
			margin: 0 auto;
			max-width: 660px;
			padding-top: 30px;
			padding-bottom: 24px;
			overflow: hidden;
			display: flex;
			flex-direction:column;
			@include space15;
			@media #{$size_700}{
				flex-direction:column;
				@include space15;
			}
			@include transition01;
			&:hover{
				@include transition01;
				background-color: #fce6c5;
				border:solid 1px #fce6c5;

			}
			.gd08_left{
				max-width: 558px;
				display: flex;
				align-items:center;
				margin: 0 auto;
				margin-bottom: 27px;
				@media #{$size_700}{
					margin-bottom: 15px ;
				}
				figure{
					margin: 0 auto;
					img{
						width: 100%;
					}
				}
			}
			.gd08_right{
				display: flex;
				align-items:center;
				text-align: center;
				width: 100%;
				@media #{$size_700}{
					width: 100%;
				}
				p{
					color:#0c0c0c;
					text-align: center;
					margin: 0 auto;
				}
			}

		}
	}
	#fb-root {
    display: none;
}


}

.fb_iframe_widget, .fb_iframe_widget span,
.fb_iframe_widget span iframe[style] {
    width: 100% !important;
}
