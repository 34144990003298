@charset "utf-8";
//スマホナビ

#sp_nav_btn_out{
	position: relative;
	display: none;
	z-index:200;
	@media #{$size_1000}{
		display: block;
	}
	.sp_nav_btn{
		position: fixed;
		width: 70px;
		height: 70px;
		background-color: #fff;
		text-align: center;
		top: 0px;
		right:0px;
		z-index: 210;

		&:hover{
			cursor: pointer;
		}
		.sp_nav_btn_inner{
			display: block;
			width: 100%;
			height: 100%;
			position: relative;

			b{
				display: block;
				width: 35px;
				height: 4px;
				margin: 0 auto;
				background-color: #0c0c0c;
				position: absolute;
				left: 0;
				right: 0;
				margin:auto;
				&:first-child{
					transform: translateY(20px);

				}
				&:nth-child(2){
					transform: translateY(33px);

				}
				&:nth-child(3){
					transform: translateY(46px);
				}
			}
		}
	}
}


//×ボタンに変化するとき
.sp_nav_on{
	.sp_nav_btn_inner{
		
		animation:sp_bar_inner
		0.6s
		cubic-bezier(0.19, 1, 0.22, 1)
		0.3s
		forwards;
		b{
			&:first-child{
				animation:sp_bar01
				0.6s
				cubic-bezier(0.19, 1, 0.22, 1)
				forwards;
			}
			&:nth-child(2){
				animation:sp_bar02
				0.6s
				cubic-bezier(0.19, 1, 0.22, 1)
				forwards;
			}
			&:nth-child(3){
				animation:sp_bar03
				0.6s
				cubic-bezier(0.19, 1, 0.22, 1)
				forwards;
			}
		}
	}
}

@keyframes sp_bar01 {
  0% {
  	transform: translateY(20px);
  }
  50%{
  	transform: translateY(33px) rotate(0deg);
  }
  100%{
  	transform: translateY(33px) rotate(-90deg);
  }
}

@keyframes sp_bar02 {
  0% {
  	display: block;

  }
  100%{
  	display: none;
  }
}

@keyframes sp_bar03 {
  0% {
  	transform: translateY(46px) rotateX(0deg);

  }
  100%{
  	transform: translateY(33px) rotateX(0deg);
  }
}

@keyframes sp_bar_inner {
	0% {
		transform: rotate( 0 );

	}
	100%{
		transform: rotate( -135deg );
	}
}


//元に戻るとき
.sp_nav_off{
	.sp_nav_btn_inner{
		transform: rotate( -135deg );
		animation:sp_bar_inner_close
		0.3s
		cubic-bezier(0.19, 1, 0.22, 1)
		forwards;
		b{
			transform: translateY(33px);
			&:first-child{
				animation:sp_bar01_close
				0.6s
				cubic-bezier(0.19, 1, 0.22, 1)
				0.3s
				forwards;
			}
			&:nth-child(2){
				display: none;
				animation:sp_bar02_close
				0.6s
				cubic-bezier(0.19, 1, 0.22, 1)
				0.3s
				forwards;
			}
			&:nth-child(3){
				animation:sp_bar03_close
				0.6s
				cubic-bezier(0.19, 1, 0.22, 1)
				0.3s
				forwards;
			}
		}
	}
}

@keyframes sp_bar_inner_close {
	0% {
		transform: rotate( -135deg );

	}
	100%{
		transform: rotate( 0 );
	}
}

.detail_sp_nav{
	position: relative;
	z-index: 18;
	.detail_sp_nav_inner{
		position: fixed;
		top: 70px;
		left: 0;
		width: 100%;
		z-index: 18;
		transform: translateY(-130%);
		overflow: hidden;
		nav{
			border-top: solid 1px #d2d2d2;
			a{
				height: 70px;
				background-color: #fff;
				display: block;
				width: 100%;
				border-bottom: solid 1px #d2d2d2;
				color:#0c0c0c;
				line-height: 70px;
				padding-left: 20px;
				padding-right: 20px;
				box-sizing: border-box;
				&:last-child{
					border-bottom: none;
				}
				span{

				}
			}
		}
	}

}

.sp_nav_erea_open{
	.detail_sp_nav_inner{
		animation:sp_nav_open
		1.2s
		cubic-bezier(0.19, 1, 0.22, 1)
		forwards;

	}
}

.sp_nav_erea_close{
	.detail_sp_nav_inner{
		animation:sp_nav_close
		1.2s
		cubic-bezier(0.19, 1, 0.22, 1)
		forwards;
	}
}

@keyframes sp_nav_open {
	0% {
		transform: translateY(-130%);
			}
	100%{
		transform: translateY(0);
	}
}

@keyframes sp_nav_close {
	0% {
		transform: translateY(0);

	}
	100%{
		transform: translateY(-130%);
	}
}