@charset "utf-8";
//スマホナビ

.header_out02{
	position: relative;
	z-index:20;
	@media #{$size_1000}{
		display: none;
	}
	.detail_header{
		text-align: center;
		width:100%;
		height:80px;
		position: absolute;
		top: 0;
		left: 0;
		.detail_header_inner{
			max-width: 1029px;
			margin: 0 auto;
			height: 100%;
			.detail_logo{
				float: left;
				display: flex;
				align-items:center;
				height: 100%;
				width: 175px;
				img{
					width: 100%;
				}
				a{
					display: block;
					width: 175px;
					@include transition01;
					&:hover{
						opacity: 0.6;
						@include transition01;
					}

				}
			}
			nav{
				float: right;
				overflow: hidden;
				height: 100%;
				a{
					display: block;
					float: left;
					height: 100%;
					display: flex;
					align-items:center;
					text-align: center;
					@include transition01;
					span{
						display: inline-block;
						margin: 0 auto;

					}
					&:hover{
						@include transition01;
					}
				}
				.nav02,
				.nav03,
				.nav04{
					width: 190px;
					

				}
				.nav01{
					width: 135px;
					&:hover{
						background-color: #50CEFF;
					}

				}
				.nav02{
					&:hover{
						background-color: #ee8c7f;
					}
				}
				.nav03{
					&:hover{
						background-color: #F8B95D;
					}
					
				}
				.nav04{
					&:hover{
						background-color: #81B975;
					}
					
				}


			}
		}
	}
}

.header_sp_out02{
	position: relative;
	z-index:20;
	display: none;
	@media #{$size_1000}{
		display: block;
	}
	.detail_header{
		text-align: center;
		width:100%;
		height:70px;
		position: fixed;
		top: 0;
		left: 0;
		background-color: #fff;
		overflow: hidden;
		.detail_header_inner{
			overflow: hidden;
			width: 100%;
			height: 100%;
			.detail_logo{
				width: 175px;
				float: left;
				display: flex;
				align-items:center;
				height: 100%;
				margin-left: 15px;
				img{
					width: 100%;

				}
			}
		}
	}
}
