@charset "utf-8";
.video_sp_img_active{
	@media #{$size_400}{
		display: none !important;			
	}
}
.top_new02{
		.movie_out{
			position: relative;
			z-index: 0;
			background-color: #000;
			width: 100%;
			&:after{
				position: fixed;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
				content:"";
				background-color: #000;
				opacity: 0.2;
				display: block;
				z-index: 0;
			}
			video{
				@media screen and (min-width: 1900px) {
					width: 130%;
				}
				position: fixed;
				top: 50%;
				transform:translate(-50%,-50%);
				left: 50%;
				margin:auto;
				z-index: 0;
				background-color: #000;
				width: 100%;
				min-width:100vh;
				min-height: 100vh;
				@media screen and (max-width: 1900px) {
					width: 110%;
				}
				@media screen and (max-width: 1600px) {
					width: 120%;
				}
				@media screen and (max-width: 1600px) {
					width: 130%;
				}
				@media screen and (max-width: 1500px) {
					width: 140%;
				}
				@media screen and (max-width: 1400px) {
					width: 150%;
				}
				@media screen and (max-width: 1300px) {
					width: 155%;
				}
				/*@media #{$size_1300}{
					width: auto;
					height: 100%;
					min-height: auto;
				}*/
				/*
				@media screen and (max-width: 1700px) and (min-height:600px){
					min-width: auto;
					min-height:100vh;
					height: 100%;
					width: auto;
				}
				*/
				@media #{$size_1100}{
					display: none;
				}
			}
			span{
				display: none;
				@media #{$size_1100}{
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					margin:auto;
					z-index: 0;
					background-image: url(/assets/img/home/main_back_new.png);
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					display: block;
					width: 100%;
					height: 100vh;
				}
			}
			
		}
	.gd_main{
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		height:100vh;
		position: relative;
		z-index:1;
		
		@include space15;
		.gd_main_inner{
			max-width: 350px;
			margin: 0 auto;
			height: 60vh;
			position: relative;
			@media #{$size_1300}{
				height: 60vh;
			}

			.main_h1_text_out{
				h1{
					max-width: 350px;
					padding-bottom: 31px;
					margin-bottom: 29px;
					position: relative;
					img{
						width: 100%;
					}
					@media #{$size_1300}{
						max-width: 250px;
					}
					@media #{$size_600}{
						max-width: 200px;
					}

				}
				span{

				}
				p{
					max-width: 115px;
					margin: 0 auto;
					display: inline-block;
					img{
						width: 100%;
					}
				}
			}
			.scroll{
				position: static;
				left: 0;
				right: 0;
				margin:auto;
				bottom: 0;
				text-align: center;
				.scroll_txt{
					width: 67px;
					margin: 0 auto 1px auto;
					img{
						width: 100%;
					}
				}
				.scroll_arrow{
					width: 19px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}

			}
		}
	}

	.nt_content01{
		background-size: cover;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		text-align: center;
		padding-top: 175px;
		padding-bottom: 175px;
		box-sizing: border-box;
		height: 100vh;
		position: relative;
		z-index: 1;
		@media #{$size_400}{
			height: auto;
			padding-top: 80px;
			padding-bottom: 0px;
		}
		.detail_h2_title02{
			margin-bottom: 44px;
		}
		.nt_content01_inner{
			background-color: rgba(255,255,255,0.9);
			margin: 0 auto;
			max-width: 750px;
			width: 100%;
			padding-top: 90px;
			padding-bottom: 90px;
			padding-left: 30px;
			padding-right: 30px;
			box-sizing: border-box;
			@media #{$size_1300}{
				padding-top: 60px;
				padding-bottom: 60px;
			}
			@media #{$size_400}{
				background-color: #fff;
			}
			.detail_h2_title02{
				margin-bottom: 30px;
			}
			.ntc01_txt01{
				@include fontsize(22);
				@include hiragino;
				letter-spacing: 0.2em;
				font-weight: bold;
				margin-bottom: 60px;
				.br01{
					display: none;
				}
				@media #{$size_1300}{
					margin-bottom: 30px;
					@include fontsize(18);
				}
				@media #{$size_600}{
					
				}
				@media #{$size_500}{
					@include fontsize(14);
					.br01{
						display: block;
					}
					
				}
				@media #{$size_350}{
					letter-spacing: 0;
					.br02{
						display: none;
					}
				}
			}
			.ntc01_txt02{
				@include fontsize(14);
				@include hiragino;
				line-height: 2.6;
				@media #{$size_600}{
					br{
						display: none;
					}
					text-align: left;
					line-height: 2;
				}
			}

		}

	}
	/* ------------------- content02 ----------------------- */
	.content02{
		
		background: #B5D5A9;
		padding: 72px 0 260px;
		padding: 0;
		text-align: center;
		position: relative;
		transform: translate3d(0,0,0);
		-webkit-transform: translate3d(0,0,0);
	}
	.content02 h2{
		position: static;
		top: 44px;
		top: 64px;
		left: 50%;
		margin-left: 0px;
		max-width: auto;
		height: auto;
		z-index: 5;
		text-align: center;
	}
	
	.content02{
		position: relative;
		z-index: 1;
		.detail_h2_title02{
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			margin:auto;
			top: 88px;
			z-index: 10;
			h2{
				margin: 0 auto 15px auto;
				color:#fff;
			}
			p{
				color:#fff;
			}
		}
		.con02Menu{
			position:relative;
			z-index:1;
			li{
				
				@media #{$size_768}{
					padding-bottom: 80px;
					border-bottom:solid 1px #9fc794;
					&:first-child{
						padding-top: 180px;
						margin-bottom: 0;
					}
				}
			}
		}

	}
	.nt_out02
	{
		background-color: #fff;
		position: relative;
		z-index: 1;
		transform: translate3d(0,0,0);
		-webkit-transform: translate3d(0,0,0);
	}
}

.gd_main_out{
	position: relative;
	top: 0;
	z-index:1;
	.gd_main{
		position: fixed;
		z-index: 2;

	}
}
.fixed03{
	position: relative;
	top: 0;
	z-index:0;
	.fixed03_inner{
	padding-top: 100vh;
	width:100%;
	
	}
	.nt_content01_scroll{
		padding-top: 0;
		transition: 1.2s cubic-bezier(.165,.84,.44,1);
		position: absolute;
		top:0;
	}
}

.gd_main_scroll{
	transform: translateY(-100vh) ;
	@include transition01;
}

@media only screen and (max-device-width:737px) and (orientation:landscape) {
	.scroll{
		display:none;
	}
	.gd_main,.nt_content01{
		height: auto !important;
		min-height: 100vh;
	}
}

.nt_movie_content{
	position: relative;
	z-index:1;
	background-color: #fff;
	padding-top: 70px;
	padding-bottom: 70px;
	text-align: center;
	@media #{$size_400}{
		border-top: solid 1px #d2d2d2;
	}
	.nt_movie_inner{
		margin: 0 auto;
		max-width: 1200px;
		.detail_h2_title02{
			margin-bottom: 64px;
			h2{
			opacity: 0;
			
			}
			p{

			opacity: 0;

			}
		}
		.nt_movie_box01{
			opacity: 0;
		}
		@media #{$size_1200}{
			@include space15;
		}
		.nt_movie_box01{
			video{
				width: 100%;
			}
			
		}

	}
	
}

.movie_player{
	vertical-align:center;
	overflow: hidden;
	display:inline-block;
	input{
		background-color: transparent;
		border:none;
		text-indent: -9999px;
		display: block;
		float: left;
		&:hover{
			cursor: pointer;
			opacity: 0.6;
		}
	}
	.saisei{
		background-image: url(/assets/img/home/saisei.png);
		background-repeat: no-repeat;
		background-size: 40px 40px;
		width: 40px;
		height: 40px;
		margin-right: 7px;
	}
	.teishi{
		background-image: url(/assets/img/home/teishi.png);
		background-repeat: no-repeat;
		background-size: 40px 40px;
		width: 40px;
		height: 40px;
		margin-right: 7px;

	}
	.vol_out{
		display: inline-block;
		background-color: #eefaff;
		box-sizing: border-box;
		height: 40px;
		padding-left: 10px;
		padding-right: 10px;
		float: left;
		overflow: hidden;

		span{
			background-image: url(/assets/img/home/vol.png);
			background-repeat: no-repeat;
			background-size: 17px 28px;
			width: 17px;
			height: 28px;
			display: inline-block;
			margin-top: 5px;
			float: left;
			margin-right: 10px;

		}
	}
	.v_up{
		background-image: url(/assets/img/home/vol_up.png);
		background-repeat: no-repeat;
		background-size: 12px 27px;
		width: 12px;
		height: 27px;
		margin-top: 5px;
		margin-right: 10px;

	}
	.v_down{
		background-image: url(/assets/img/home/vol_down.png);
		background-repeat: no-repeat;
		background-size: 12px 27px;
		width: 12px;
		height: 27px;
		margin-top: 5px;

	}
}




