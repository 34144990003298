@charset "utf-8";
@font-face {
  font-family: 'NotoSansCJKjp-Black';
  /*01*/
  src: url("/assets/font/NotoSansCJKjp-Black_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Bold';
  /*02*/
  src: url("/assets/font/NotoSansCJKjp-Bold_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-DemiLight';
  /*03*/
  src: url("/assets/font/NotoSansCJKjp-DemiLight_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Light';
  /*04*/
  src: url("/assets/font/NotoSansCJKjp-Light_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Medium';
  /*05*/
  src: url("/assets/font/NotoSansCJKjp-Medium_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Regular';
  /*06*/
  src: url("/assets/font/NotoSansCJKjp-Regular_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Thin';
  /*07*/
  src: url("/assets/font/NotoSansCJKjp-Thin_min.woff");
}

@mixin font01{
	font-family: 'NotoSansCJKjp-Black',sans-serif;/*01*/
}
@mixin font02{
	font-family: 'NotoSansCJKjp-Bold',sans-serif;/*02*/
}
@mixin font03{
	font-family: 'NotoSansCJKjp-DemiLight',sans-serif;/*03*/
}
@mixin font04{
	font-family: 'NotoSansCJKjp-Medium',sans-serif;/*05*/
}
@mixin font05{
	font-family: 'NotoSansCJKjp-Regular',sans-serif;/*06*/
}
@mixin font06{
	font-family: 'NotoSansCJKjp-Thin',sans-serif;/*07*/
}
@mixin font07{
	font-family: 'NotoSansCJKjp-Light',sans-serif;/*04*/
}