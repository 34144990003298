@charset "utf-8";
.top_new{
	.gd_main{
		background-image: url(/assets/img/home/top_new_back01.png);
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		height:100vh;
		position: relative;
		z-index:0;
		
		@include space15;
		.gd_main_inner{
			max-width: 350px;
			margin: 0 auto;
			height: 65vh;
			position: relative;

			.main_h1_text_out{
				h1{
					max-width: 350px;
					padding-bottom: 31px;
					margin-bottom: 29px;
					position: relative;
					img{
						width: 100%;
					}
					@media #{$size_600}{
						max-width: 200px;
					}

				}
				span{

				}
				p{
					max-width: 115px;
					margin: 0 auto;
					display: inline-block;
					img{
						width: 100%;
					}
				}
			}
			.scroll{
				position: absolute;
				left: 0;
				right: 0;
				margin:auto;
				bottom: 0;
				text-align: center;
				.scroll_txt{
					width: 67px;
					margin: 0 auto 1px auto;
					img{
						width: 100%;
					}
				}
				.scroll_arrow{
					width: 19px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}

			}
		}
	}

	.nt_content01{
		background-image: url(/assets/img/home/top_new_back02.png);
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		text-align: center;
		padding-top: 175px;
		padding-bottom: 175px;
		box-sizing: border-box;
		padding-left: 30px;
		padding-right: 30px;
		.detail_h2_title02{
			margin-bottom: 44px;
		}
		.nt_content01_inner{
			background-color: rgba(255,255,255,0.9);
			margin: 0 auto;
			max-width: 750px;
			width: 100%;
			padding-top: 90px;
			padding-bottom: 90px;
			padding-left: 30px;
			padding-right: 30px;
			box-sizing: border-box;
			.ntc01_txt01{
				@include fontsize(22);
				@include hiragino;
				letter-spacing: 0.2em;
				font-weight: bold;
				margin-bottom: 60px;
				@media #{$size_700}{
					@include fontsize(18);
				}
				@media #{$size_600}{
					br{
						display: none;
					}
					text-align: left;
				}
				@media #{$size_400}{
					@include fontsize(14);
				}
			}
			.ntc01_txt02{
				@include fontsize(13);
				@include hiragino;
				line-height: 2.0;
				@media #{$size_600}{
					br{
						display: none;
					}
					text-align: left;
				}
			}

		}

	}
	/* ------------------- content02 ----------------------- */
	.content02{
		
		background: #B5D5A9;
		padding: 72px 0 260px;
		padding: 0;
		text-align: center;
		position: relative;
	}
	.content02 h2{
		position: static;
		top: 44px;
		top: 64px;
		left: 50%;
		margin-left: 0px;
		max-width: auto;
		height: auto;
		z-index: 5;
		text-align: center;
	}
	
	.content02{
		position: relative;
		.detail_h2_title02{
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			margin:auto;
			top: 88px;
			z-index: 10;
			h2{
				margin: 0 auto 15px auto;
				color:#fff;
			}
			p{
				color:#fff;
			}
		}
		.con02Menu{
			li{
				border-bottom:solid 1px #9fc794;
				@media #{$size_768}{
					padding-bottom: 80px;
					&:first-child{
						padding-top: 180px;
						margin-bottom: 0;
					}
				}
			}
		}

	}

	
}

