@charset "utf-8";
.archive,
.single,
.page-template-page-blog_top{
	.detail_header{
		border-bottom: 1px solid rgba(255,255,255,0.3);
	}
}
.blog_main{
	background-image: url(/assets/img/blog/blog_back01.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding-top: 80px;
	margin-bottom: 40px;
	.blog_main_inner{
		height: 290px;
		display: flex;
		align-items: center;
		text-align: center;

		.detail_h2_title02_blog{
			margin: 0 auto;
			span{
				color: #fff;
			}
			p{
				color:#fff;
			}
		}
		.detail_h2_title02_blog01{
			margin: 0 auto;
			h1{
				color: #fff;
			}
			p{
				color:#fff;
			}
		}
	}
}

.blog_nav{
	text-align: center;
	nav{
		max-width: 960px;
		@include space15;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		@media #{$size_1000}{
			margin-bottom: 30px;
		}
		a{
			width: 25%;
			display: block;
			height: 100px;
			color:#0c0c0c;
			display: flex;
			align-items:center;
			position: relative;
			text-align: center;
			@media #{$size_1000}{
				width: 50%;
				height: auto;
				margin-bottom: 15px;
				@include space15;
			}

			span{
				height: 100px;
				line-height: 100px;
				position: relative;
				margin: 0 auto;
				br{
					display: none;
				}
				@media #{$size_1000}{
					background-color: #999;
					width: 100%;
					height: auto;
					line-height: 1.2;
					padding:10px;
					box-sizing: border-box;
					border-radius: 8px;
					color:#fff;
				}
				@media #{$size_600}{
					height: 60px;
					br{
						display: block;
					}
				}
				&:before{
					width: 100%;
					content:"";
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin:auto;
					height: 4px;

					transform:scaleX(0);
					@include transition02;
					@media #{$size_1000}{
						display: none;
					}
				}

			}
			&:hover{
				span{
					&:before{
						transform:scaleX(1);
						@include transition02;
					}
				}
			}
		}
		@media #{$size_1000}{
			.bn_cat01{
				span{
				background-color: #50ceff;
				@media #{$size_600}{
					padding-top: 25px;
				}
				}

			}
			.bn_cat02{
				span{
				background-color: #ee8c7f;
				@media #{$size_600}{
					padding-top: 15px;
				}
				}

			}
			.bn_cat03{
				span{
				background-color: #f8b95d;
				@media #{$size_600}{
					padding-top: 15px;
				}
				}

			}
			.bn_cat04{
				span{
				background-color: #81b975;
				@media #{$size_600}{
					padding-top: 25px;
				}
				}

			}
		}
	}
}

.page-template-page-blog_top{
	.blog_nav{

		a{
			.blog_color01{
				&:before{
					transform:scaleX(1) !important;
				}

			}
		}
	}

}
.category-glamorous_dining{
	.blog_nav{

		a{
			.blog_color02{
				&:before{
					transform:scaleX(1) !important;
				}

			}
		}
	}
}
.category-foret_aventure{
	.blog_nav{

		a{
			.blog_color03{
				&:before{
					transform:scaleX(1) !important;
				}

			}
		}
	}
}
.category-class_vesso{
	.blog_nav{

		a{
			.blog_color04{
				&:before{
					transform:scaleX(1) !important;
				}

			}
		}
	}
}

.blog_color01{
	&:before{
		background-color: #50ceff;
	}
}

.blog_color02{
	&:before{
		background-color: #ee8c7f;
	}
}
.blog_color03{
	&:before{
		background-color: #f8b95d;
	}
}
.blog_color04{
	&:before{
		background-color: #81b975;
	}
}
.blog_list{
	text-align: center;
	article{
		padding-top: 23px;
		padding-bottom: 23px;
		@media #{$size_1000}{
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.article_inner{
			max-width: 1200px;
			@include space15;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			@media #{$size_1000}{
				flex-direction: column;
			}
			.post_header{
				display: flex;
				align-items:center;
				flex-direction: row;
				height: 100%;
				@media #{$size_1000}{
					margin-bottom: 20px;
				}
				.date{
					width: 124px;
				}
				.cat{
					width: 180px;
					height: 24px;
					border-radius: 12px;
					color:#fff;
					line-height: 24px;
					@include fontsize(12);
					display: block;
					@include transition02;
					span{

					}
					&:hover{
						@include transition02;
					}
				}
			}
			.post_content{
				margin-left: 20px;
				padding-left: 15px;
				display: block;
				color:#0c0c0c;
				position: relative;
				width: 100%;
				text-align: left;
				box-sizing: content-box;
				@media #{$size_1000}{
					margin-left: 0;
				}
				span{
					position: relative;
					display: inline-block;
					&:before{
						content:"";
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						background-color: #999;
						width: 100%;
						height: 1px;
						z-index: 0;
						transform: scaleX(0);
						@include transition02;

					}
				}

				&:hover{
					span{
						&:before{
							transform:scaleX(1);
							@include transition02;
						}
					}
				}
			}

		}
		&:nth-child(odd){
			background-color: #f7f7f7;
		}
		&:nth-child(even){
			background-color: #e9e9e9;
		}
		.foret_aventure_cat_link{
			.post_header{
				.cat{
					background-color: #f8b95d;
					&:hover{
						opacity: 0.8;
					}
				}
			}
		}
		.glamorous_dining_cat_link{
			.post_header{
				.cat{
					background-color: #ee8c7f;
					&:hover{
						opacity: 0.8;
					}
				}
			}
		}
		.class_vesso_cat_link{
			.post_header{
				.cat{
					background-color: #81b975;
					&:hover{
						opacity: 0.8;
					}
				}
			}
		}
		.morigurashi_cat_link{
			.post_header{
				.cat{
					background-color: #50ceff;
					&:hover{
						opacity: 0.8;
					}

				}
			}
		}
	}
	.glamorous_dining_cat_link_out{
		&:nth-child(even){
			background-color: #fff;
		}
		&:nth-child(odd){
			background-color: #fff3f1;
		}

	}
	.foret_aventure_cat_link_out{
		&:nth-child(even){
			background-color: #fff;
		}
		&:nth-child(odd){
			background-color: #fff5e7;
		}

	}
	.class_vesso_cat_link_out{
		&:nth-child(even){
			background-color: #fff;
		}
		&:nth-child(odd){
			background-color: #e8f7e4;
		}

	}

}

.pager_out{
	padding-top: 50px;
	padding-bottom: 50px;
	span,a{
		padding:10px;
		height: 20px;
		background-color: #d6d6d6;
		display: inline-block;
		color:#fff;

	}
	.current{
		background-color:#50ceff;
	}
	a{
		@include transition02;
		&:hover{
			background-color:#50ceff;
			@include transition02;
		}
	}
}
.class_vesso_pager{
	.current{
		background-color:#81b975;
	}
	a{
		&:hover{
			background-color:#81b975;
		}
	}
}
.class_vesso_pager{
	.current{
		background-color:#81b975;
	}
	a{
		&:hover{
			background-color:#81b975;
		}
	}
}
.glamorous_dining_pager{
	.current{
		background-color: #ee8c7f;
	}
	a{
		&:hover{
			background-color: #ee8c7f;
		}
	}
}

.foret_aventure_pager{
	.current{
		background-color: #f8b95d;
	}
	a{
		&:hover{
			background-color: #f8b95d;
		}
	}
}


///////////////////詳細ページ

.blog_detail_out{
	text-align: center;
	margin-bottom: 70px;
	.blog_detail_inner{
		max-width: 830px;
		@include space15;
		margin: 0 auto;
		article{
			text-align: center ;
			.blog_detail_post_header{
				display: inline-block;
				overflow: hidden;
				margin: 0 auto;
				padding-top: 40px;
				padding-bottom: 27px;
				li{
					float: left;
					display: inline-block;
				}
				.blog_detail_date{
					@include fontsize(13);
					padding-left: 15px;
					padding-right: 15px;
					border-right: solid 1px #ddd;
					height: 24px;
					line-height: 24px;
				}
				.blog_detail_cat{
					margin-left: 15px;
					margin-right: 15px;
					width: 180px;
					height: 20px;
					border-radius: 10px;
					@include fontsize(14);
					width: 180px;
					height: 24px;
					border-radius: 12px;
					background-color: #ccc;
					color: #fff;
					a{
						color:#fff;
						line-height: 24px;
					}
				}
				.foret_aventure_cat_link{
					background-color: #f8b95d;
					&:hover{
						background-color: #d39d4f;
					}

				}
				.glamorous_dining_cat_link{
					background-color: #ee8c7f;
					&:hover{
						background-color: #cb776c;
					}

				}
				.class_vesso_cat_link{

					background-color: #81b975;
					&:hover{
						background-color: #6e9d64;
					}

				}
				.morigurashi_cat_link{
					background-color: #50ceff;
					&:hover{
						background-color: #44afd9;
					}
				}
			}
			h1{
				@include fontsize(30);
			}
			.content_body{
				padding-bottom: 60px;
				p{
					padding-top: 20px;
					padding-bottom: 20px;
					text-align: left;
					img{
						max-width: 100%;
						width: auto;
						height: auto;
					}
				}
			}

		}
		.blog_detail_list_btn{
			display: block;
			margin: 0 auto;
			&:hover{
				opacity: 0.8;
			}
			@media #{$size_800}{
				width: 230px;
				img{
					width: 100%;
				}
			}
		}

	}

}