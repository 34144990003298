@charset "utf-8";
$img_common:'/common/images/common/';
$size_1800: "only screen and (max-width: 1800px)";
$size_1700: "only screen and (max-width: 1700px)";
$size_1600: "only screen and (max-width: 1600px)";
$size_1500: "only screen and (max-width: 1500px)";
$size_1400: "only screen and (max-width: 1400px)";
$size_1300: "only screen and (max-width: 1300px)";
$size_1200: "only screen and (max-width: 1200px)";
$size_1160: "only screen and (max-width: 1160px)";
$size_1100: "only screen and (max-width: 1100px)";
$size_1000: "only screen and (max-width: 1000px)";
$size_960: "only screen and (max-width: 960px)";
$size_900: "only screen and (max-width: 900px)";
$size_800: "only screen and (max-width: 800px)";
$size_768: "only screen and (max-width: 768px)";
$size_700: "only screen and (max-width: 700px)";
$size_600: "only screen and (max-width: 600px)";
$size_500: "only screen and (max-width: 500px)";
$size_480: "only screen and (max-width: 480px)";
$size_400: "only screen and (max-width: 400px)";
$size_350: "only screen and (max-width: 350px)";
$size_300: "only screen and (max-width: 300px)";
// ex @media #{$size_1200}{

@mixin fontsize($size: 24, $base: 13) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}


@mixin space15{
	box-sizing:border-box;
	padding-left: 15px;
	padding-right: 15px;  
}

@mixin hiragino{
	font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,  sans-serif !important;
}

@mixin meirio{
	font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',   sans-serif !important;
}

@mixin lato{
	font-family: 'Roboto', sans-serif;
}

@mixin transition01{
	transition: 0.5s cubic-bezier(.165,.84,.44,1);
}

@mixin transition02{
	transition: 0.2s cubic-bezier(.165,.84,.44,1);
}


